import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-card-cta',
    cardNews: '.card-cta__article'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    const cardNews = document.querySelectorAll(selectors.cardNews);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'loop',
          autoplay: false,
          drag: true,
          width: '100%',
          speed: 1000,
          gap: 42,
          perPage: 3,
          perMove: 1,
          breakpoints: {
            1024: {
              perPage: 2,
              drag: true
            },
            640: {
              perPage: 1,
              drag: true
            }
          },
          arrows: true,
          pagination: false,
          classes: {
            arrow: 'splide__arrow card-news__article-arrow',
            prev: 'splide__arrow--prev card-news__article-arrow--prev',
            next: 'splide__arrow--next card-news__article-arrow--next'
          }
        }).mount();
      }
    });

    if (window.innerWidth > 1024) {
      for (const cardNew of cardNews) {
        cardNew.addEventListener('mouseenter', (e) => {
          addOpacityClassToCardNews();
          e.currentTarget.classList.add('card-news__article--active');
        });

        cardNew.addEventListener('mouseleave', (e) => {
          removeOpacityClassToCardNews();
        });
      }
    }

    function addOpacityClassToCardNews () {
      for (const cardNew of cardNews) {
        cardNew.classList.add('card-news__article--paused');
      }
    }

    function removeOpacityClassToCardNews () {
      for (const cardNew of cardNews) {
        cardNew.classList.remove('card-news__article--paused');
        cardNew.classList.remove('card-news__article--active');
      }
    }
  };

  window.addEventListener('load', init);
})();
