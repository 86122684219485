import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-hot-news'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'slide',
          rewind: true,
          autoplay: false,
          drag: true,
          speed: 1000,
          perPage: 1,
          perMove: 1,
          arrows: true,
          pagination: false,
          classes: {
            arrow: 'splide__arrow hot-news__content-card-arrow',
            prev: 'splide__arrow--prev hot-news__content-card-arrow--prev',
            next: 'splide__arrow--next hot-news__content-card-arrow--next'
          }
        }).mount();
      }
    });
  };

  window.addEventListener('load', init);
})();
