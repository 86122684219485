import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-logo-slider'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        const loop = splide.getAttribute('data-loop');
        const slide = splide.getAttribute('data-slide');
        const autoplay = splide.getAttribute('data-autoplay');

        new Splide(splide, {
          type: loop ? 'loop' : 'slide',
          autoplay: slide ? !!autoplay : false,
          drag: !!slide,
          speed: 1000,
          perPage: 4,
          breakpoints: {
            1024: {
              perPage: 2,
              drag: true
            }
          },
          gap: 24,
          arrows: false,
          pagination: false
        }).mount();
      }
    });
  };

  window.addEventListener('load', init);
})();
