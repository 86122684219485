import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-card-news',
    cardCta: '.js-card-cta__single'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    const cardCta = document.querySelectorAll(selectors.cardCta);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'loop',
          autoplay: false,
          drag: true,
          speed: 1000,
          gap: 42,
          perPage: 4,
          perMove: 1,
          breakpoints: {
            1400: {
              perPage: 3,
              drag: true
            },
            1024: {
              perPage: 2,
              drag: true,
              padding: {
                right: '1%',
                left: '5%'
              }
            },
            640: {
              gap: 24,
              perPage: 1,
              drag: true,
              padding: {
                right: '12.5%',
                left: '5%'
              }
            }
          },
          arrows: true,
          pagination: false,
          classes: {
            arrow: 'splide__arrow card-news__article-arrow',
            prev: 'splide__arrow--prev card-news__article-arrow--prev',
            next: 'splide__arrow--next card-news__article-arrow--next'
          }
        }).mount();
      }
    });

    if (window.innerWidth > 1024) {
      for (const cardNew of cardCta) {
        cardNew.addEventListener('mouseenter', (e) => {
          addOpacityClassToCardNews();
          e.currentTarget.classList.add('card-cta__article--active');
        });

        cardNew.addEventListener('mouseleave', (e) => {
          removeOpacityClassToCardNews();
        });
      }
    }

    function addOpacityClassToCardNews () {
      for (const cardNew of cardCta) {
        cardNew.classList.add('card-cta__article--paused');
      }
    }

    function removeOpacityClassToCardNews () {
      for (const cardNew of cardCta) {
        cardNew.classList.remove('card-cta__article--paused');
        cardNew.classList.remove('card-cta__article--active');
      }
    }
  };

  window.addEventListener('load', init);
})();
