(function () {
  'use strict';

  const selectors = {
    orderBy: '.js-order-by',
    childTax: '.js-button-child-tax',
    eventButton: '.js-button-past-event'
  };

  const init = () => {
    const orderBy = document.querySelector(selectors.orderBy);
    const childTaxs = document.querySelectorAll(selectors.childTax);
    const eventButton = document.querySelector(selectors.eventButton);

    if (eventButton instanceof HTMLElement) {
      eventButton.addEventListener('click', () => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('past-event', 'true');
        window.location.href = currentUrl.toString();
      });
    }

    if (orderBy instanceof HTMLElement) {
      orderBy.addEventListener('change', (event) => {
        const selectedValue = event.currentTarget.value;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('order_by', selectedValue);
        window.location.href = currentUrl.toString();
      });
    }

    childTaxs.forEach(childTax => {
      if (childTax instanceof HTMLElement) {
        childTax.addEventListener('click', (e) => {
          const selectedValue = e.currentTarget.dataset.value;
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set('child_tax', selectedValue);
          window.location.href = currentUrl.toString();
        });
      }
    });
  };

  window.addEventListener('load', init);
})();
