(function () {
  'use strict';

  const selectors = {
    buttons: '.footer__top-services-button-single',
    tabs: '.footer__top-services-element-wrapper'
  };

  const init = () => {
    const buttons = document.querySelectorAll(selectors.buttons);
    const tabs = document.querySelectorAll(selectors.tabs);

    for (const button of buttons) {
      button.addEventListener('click', (e) => {
        removeActiveClass();
        button.classList.add('footer__top-services-button-single--active');
        document.querySelector(`[data-parent="${e.currentTarget.dataset.name}"]`).classList.add('footer__top-services-element-wrapper--active');
      });
    }

    function removeActiveClass (type) {
      for (const button of buttons) {
        button.classList.remove('footer__top-services-button-single--active');
      }

      for (const tab of tabs) {
        tab.classList.remove('footer__top-services-element-wrapper--active');
      }
    }
  };

  window.addEventListener('load', init);
})();
