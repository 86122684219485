(function () {
  'use strict';

  const selectors = {
    calendarPrevious: '#js-calendar-previous',
    calendarNext: '#js-calendar-next',
    calendarBody: '#calendar-body',
    monthAndYear: '#monthAndYear',
    formCalendar: '.js__title-form-room',
    beforeDays: '.js__before-days-room',
    afterDays: '.js__after-days-room',
    endDate: '.js-end-date',
    endDateDev: '.js-end-date-dev',
    beginDate: '.js-begin-date',
    beginDateDev: '.js-begin-date-dev',
    room: '.js-room',
    calendar: '.calendar',
    submitButton: '.wpforms-submit',
    calendarForm: '#calendar-form',
    dynamicCalendar: '#dynamic-calendar'
  };

  const init = () => {
    const calendarPrevious = document.querySelector(selectors.calendarPrevious);
    const calendarNext = document.querySelector(selectors.calendarNext);
    const formCalendar = document.querySelector(`${selectors.formCalendar} input`);
    const beforeDays = document.querySelector(`${selectors.beforeDays} input`);
    const afterDays = document.querySelector(`${selectors.afterDays} input`);
    const endDate = document.querySelector(`${selectors.endDate} input`);
    const endDateDev = document.querySelector(`${selectors.endDateDev} input`);
    const beginDate = document.querySelector(`${selectors.beginDate} input`);
    const beginDateDev = document.querySelector(`${selectors.beginDateDev} input`);
    const calendar = document.querySelector(selectors.calendar);
    const room = document.querySelector(`${selectors.room} select`);
    const dynamicCalendar = document.querySelector(selectors.dynamicCalendar);

    if (room instanceof HTMLElement && formCalendar instanceof HTMLElement && endDate instanceof HTMLElement && beginDate instanceof HTMLElement) {
      room.addEventListener('change', () => {
        const begin = beginDate.value;
        const end = endDate.value;
        formCalendar.value = `${room.options[room.selectedIndex].text} : ${begin} -  ${end}`;
        beforeDays.value = calendar.dataset.before;
        afterDays.value = calendar.dataset.after;
      });

      beginDate.addEventListener('blur', () => {
        const begin = beginDate.value;
        const end = endDate.value;
        formCalendar.value = `${room.options[room.selectedIndex].text} : ${begin} -  ${end}`;
        beforeDays.value = calendar.dataset.before;
        afterDays.value = calendar.dataset.after;
        endDateDev.value = convertDateFormat(end);
        beginDateDev.value = convertDateFormat(begin);
      });

      endDate.addEventListener('blur', () => {
        const begin = beginDate.value;
        const end = endDate.value;
        formCalendar.value = `${room.options[room.selectedIndex].text} : ${begin} -  ${end}`;
        beforeDays.value = calendar.dataset.before;
        afterDays.value = calendar.dataset.after;
        endDateDev.value = convertDateFormat(end);
        beginDateDev.value = convertDateFormat(begin);
      });
    }

    function convertDateFormat (date) {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    }

    if (calendarPrevious instanceof HTMLElement) {
      calendarPrevious.addEventListener('click', function (e) {
        fetchData(e);
      });
    }

    if (calendarNext instanceof HTMLElement) {
      calendarNext.addEventListener('click', function (e) {
        fetchData(e);
      });
    }

    if (dynamicCalendar instanceof HTMLElement) {
      dynamicCalendar.addEventListener('change', function (e) {
        fetchData(e);
      });
    }

    function fetchData (e) {
      const ajaxurl = e.currentTarget.dataset.ajaxurl;
      const data = {
        action: e.currentTarget.dataset.action,
        nonce: e.currentTarget.dataset.nonce,
        month: e.currentTarget.dataset.month,
        year: e.currentTarget.dataset.year
      };

      document.querySelector('.js-calendar-loader').classList.add('calendar-loader--active');

      fetch(ajaxurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache'
        },
        body: new URLSearchParams(data)
      })
        .then(response => response.json())
        .then(responseData => {
          setTimeout(() => {
            document.querySelector('.js-calendar-loader').classList.remove('calendar-loader--active');
          }, 500);
          changeCalendarName(responseData);
          changeDayOfTheMonth(responseData);
        })
        .catch(error => {
          console.log([error]);
        });
    }
  };

  function changeCalendarName (response) {
    const calendarBody = document.querySelector(selectors.calendarBody);
    const dynamicCalendar = document.querySelector(selectors.dynamicCalendar);
    let htmlContent = '';

    // Vérification de la langue dans l'URL
    const isGerman = window.location.pathname.includes('/de/');

    for (const element of response.data.elements[dynamicCalendar.options[dynamicCalendar.selectedIndex].text]) {
      htmlContent += '<tr>';
      element.forEach(el => {
        htmlContent += `
        <td class="calendar__page-td calendar__page-td--${el.events}">
          <div class="calendar__page-td-child">
            <p class="calendar__page-td-day">${el.day}</p>
            <p class="calendar__page-td-status calendar__page-td-status--${el.events}">${
          el.events === 'canceled'
            ? isGerman ? 'Verfügbar' : 'Disponible'
            : el.events === 'in_process'
              ? isGerman ? 'Wartet auf Bestätigung' : 'En attente de confirmation'
              : el.events === 'confirmed'
                ? isGerman ? 'Nicht verfügbar' : 'Non disponible'
                : ''
        }</p>
          </div>
        </td>`;
      });
      htmlContent += '</tr>';
    }

    calendarBody.innerHTML = htmlContent;

    const calendarPrevious = document.querySelector(selectors.calendarPrevious);
    const calendarNext = document.querySelector(selectors.calendarNext);

    calendarPrevious.dataset.month = response.data.month;
    calendarNext.dataset.month = response.data.month;
    dynamicCalendar.dataset.month = response.data.month;

    calendarPrevious.dataset.year = response.data.year;
    calendarNext.dataset.year = response.data.year;
    dynamicCalendar.dataset.year = response.data.year;
  }

  function changeDayOfTheMonth (response) {
    const monthAndYear = document.querySelector(selectors.monthAndYear);

    monthAndYear.textContent = response.data.name;
  }

  window.addEventListener('load', init);
})();
