(function () {
  'use strict';

  const classes = {
    buttonActive: 'accordion-council__question__btn--active',
    questionActive: 'accordion-council__question--active'
  };

  const selectors = {
    accordionItem: '.accordion-council__item',
    accordionDescriptionWrapper: '.accordion-council__description__wrapper',
    accordionQuestion: '.accordion-council__question',
    accordionQuestionButton: '.js-accordion-council__question__btn'
  };

  const init = () => {
    const accordions = document.querySelectorAll(selectors.accordionItem);

    function fetchData (e) {
      const ajaxurl = e.currentTarget.dataset.ajaxurl;
      const data = {
        action: e.currentTarget.dataset.action,
        nonce: e.currentTarget.dataset.nonce,
        id: e.currentTarget.dataset.id
      };

      fetch(ajaxurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache'
        },
        body: new URLSearchParams(data)
      })
        .then(response => response.json())
        .then(responseData => {
          const content = [];
          for (const data of responseData.data.steps) {
            content.push(`
    <div class="accordion-council__description accordion-council__description--council">
      <p class="accordion-council__description--council-title">${data.headline}</p>
      <div class="accordion-council__description--council-text">
        ${data.data}
      </div>
    </div>
  `);
          }
          const text = document.querySelector(`.accordion-council__description__wrapper[data-id="${data.id}"]`);
          console.log(text);
          if (text instanceof HTMLElement) {
            text.innerHTML = content.join(''); // Joint tout le contenu du tableau en une chaîne
          }
        })
        .catch(error => {
          console.log([error]);
        });
    }

    const toggleAccordion = async (accordion, index, e) => {
      const loader = document.querySelector('.js-council-loader');
      loader.classList.add('council-loader--active');

      await fetchData(e);

      const waitForHeight = (element, callback, interval = 500, maxRetries = 10) => {
        let retries = 0;

        const checkHeight = () => {
          retries++;

          if (element.scrollHeight > 0 || element.childElementCount > 0 || element.textContent.trim() !== '') {
            callback();
          } else if (retries >= maxRetries) {
            console.warn("L'élément semble vide après plusieurs tentatives.");
            callback();
          } else {
            setTimeout(checkHeight, interval);
          }
        };

        checkHeight();
      };

      const content = accordion.querySelector(selectors.accordionDescriptionWrapper);

      waitForHeight(content, () => {
        const isOpen = content.style.height === `${content.scrollHeight}px`;

        accordions.forEach((a, i) => {
          if (a instanceof HTMLElement) {
            const innerContent = a.querySelector(selectors.accordionDescriptionWrapper);
            const icon = a.querySelector(selectors.accordionQuestionButton);
            const question = a.querySelector(selectors.accordionQuestion);

            innerContent.style.height = i === index && !isOpen ? `${innerContent.scrollHeight}px` : '0px';
            icon.classList.toggle(classes.buttonActive, i === index && !isOpen);
            question.classList.toggle(classes.questionActive, i === index && !isOpen);
          }
        });

        loader.classList.remove('council-loader--active');
      });
    };

    accordions.forEach((accordion, index) => {
      if (accordion instanceof HTMLElement) {
        const header = accordion.querySelector(selectors.accordionQuestion);
        const content = accordion.querySelector(selectors.accordionDescriptionWrapper);

        header.addEventListener('click', (e) => toggleAccordion(accordion, index, e));
      }
    });
  };

  window.addEventListener('load', init);
})();
