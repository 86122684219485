(function () {
  'use strict';

  const selectors = {
    contrasteToggle: '#contraste-toggle'
  };

  const init = () => {
    const contrasteToggle = document.querySelector(selectors.contrasteToggle);

    const isContrasted = localStorage.getItem('contrasted') === 'true';
    if (isContrasted) {
      document.body.classList.add('contrasted');
    }

    if (contrasteToggle instanceof HTMLElement) {
      contrasteToggle.checked = isContrasted;

      contrasteToggle.addEventListener('click', () => {
        if (contrasteToggle.checked) {
          document.body.classList.add('contrasted');
          localStorage.setItem('contrasted', 'true');
        } else {
          document.body.classList.remove('contrasted');
          localStorage.setItem('contrasted', 'false');
        }
      });
    }
  };

  window.addEventListener('load', init);
})();
