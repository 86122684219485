import { Chart, registerables } from 'chart.js';

(function () {
  'use strict';

  Chart.register(...registerables);

  const selectors = {
    jsComponent: '.js-component-chart',
    buttonData: '.js-button-data'
  };

  function stringToArray (str, type) {
    if (type === 'String') {
      return str
        .split(',') // Divise la chaîne en fonction des virgules
        .filter(el => el !== '') // Filtre les éléments vides
        .map(String); // Convertit chaque élément en String
    } else {
      return str
        .split(',') // Divise la chaîne en fonction des virgules
        .filter(el => el !== '') // Filtre les éléments vides
        .map(Number); // Convertit chaque élément en nombre
    }
  }

  const init = () => {
    const statsComponent = document.querySelectorAll(selectors.jsComponent);

    for (const statComponent of statsComponent) {
      if (statComponent instanceof HTMLElement) {
        new Chart(statComponent, {
          type: statComponent.dataset.type,
          data: {
            labels: stringToArray(statComponent.dataset.name, 'String'),
            datasets: [{
              label: statComponent.dataset.title,
              data: stringToArray(statComponent.dataset.number, 'Number'),
              backgroundColor: stringToArray(statComponent.dataset.color, 'String'),
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }
    }
  };

  window.addEventListener('load', init);
})();
