(function () {
  'use strict';

  const selectors = {
    burger: '.js-burger-trigger',
    closeButton: '.close__nav__button',
    navigationLinks: '.js-navigation-link',
    checkboxSelector: 'checkbox_toggle',
    navigationScroll: '.js-header',
    navigationTop: '.js-navigation-top',
    iconSvg: '.js-mobile-icon-svg',
    stageForms: '.stage__forms',
    contentContainer: '.template-content__container-content',
    singleUnderNavigation: '.template-content__first-navigation'
  };

  const init = () => {
    const burger = document.querySelector(selectors.burger);
    const closeButton = document.querySelector(selectors.closeButton);
    const navigationLinks = document.querySelectorAll(selectors.navigationLinks);
    const navigationScroll = document.querySelector(selectors.navigationScroll);
    const navigationTop = document.querySelector(selectors.navigationTop);
    const body = document.querySelector('body');
    const iconSvg = document.querySelector('.js-mobile-icon-svg');
    const iconSvgUse = document.querySelector('.js-mobile-icon-svg use');
    const singleUnderNavigation = document.querySelector(selectors.singleUnderNavigation);
    const stageForms = document.querySelector(selectors.stageForms);
    const contentContainer = document.querySelector(selectors.contentContainer);

    if (navigationScroll instanceof HTMLElement && navigationTop instanceof HTMLElement) {
      let lastScrollTop = 0;
      let ticking = false;

      window.addEventListener('scroll', function () {
        if (window.innerWidth >= 1450) {
          if (!ticking) {
            window.requestAnimationFrame(function () {
              const st = window.pageYOffset || document.documentElement.scrollTop;

              if (st > lastScrollTop) {
                iconSvg.classList.add('navigation__logo-icon--resized');
                iconSvgUse.setAttribute('xlink:href', '#customer-logo-nav-mobile');
                navigationScroll.style.transform = `translateY(${navigationTop.offsetHeight * -1}px)`;

                if (singleUnderNavigation instanceof HTMLElement) {
                  singleUnderNavigation.classList.remove('template-content__first-navigation--scrolled-stage');
                }
              } else {
                iconSvg.classList.remove('navigation__logo-icon--resized');
                iconSvgUse.setAttribute('xlink:href', '#customer-logo');
                navigationScroll.style.transform = 'translateY(0)';

                if (singleUnderNavigation instanceof HTMLElement) {
                  singleUnderNavigation.classList.add('template-content__first-navigation--scrolled-stage');
                }
              }

              lastScrollTop = st <= 0 ? 0 : st;
              ticking = false;
            });

            ticking = true;
          }
        }

        if (singleUnderNavigation instanceof HTMLElement) {
          checkUnderNavigation();
        }
      });
    }
    if (singleUnderNavigation instanceof HTMLElement) {
      checkUnderNavigation();
    }

    function checkUnderNavigation () {
      if (singleUnderNavigation instanceof HTMLElement) {
        const singleUnderNavHeight = singleUnderNavigation.offsetTop;
        const stageFormsHeight = stageForms.offsetTop + stageForms.offsetHeight;
        const contentContainerHeight = contentContainer.offsetTop + contentContainer.offsetHeight;
        const scrollTop = window.scrollY;

        if (scrollTop < stageFormsHeight - 500 || contentContainerHeight < scrollTop) {
          singleUnderNavigation.classList.remove('template-content__first-navigation--scrolled');
        } else if (scrollTop >= singleUnderNavHeight - 120) {
          singleUnderNavigation.classList.add('template-content__first-navigation--scrolled');
        } else {
          singleUnderNavigation.classList.remove('template-content__first-navigation--scrolled');
        }

        const links = document.querySelectorAll('.template-single__first-navigation-link');
        let activeElementFound = false;

        links.forEach(link => {
          const href = link.getAttribute('href');
          if (href && href.startsWith('#')) {
            const id = href.substring(1);
            const targetElement = document.getElementById(id);

            if (targetElement) {
              const elementTop = targetElement.offsetTop;
              const elementBottom = elementTop + targetElement.offsetHeight;

              if (scrollTop >= elementTop - 120 && scrollTop < elementBottom - 120) {
                link.parentElement.classList.add('template-content__first-navigation-link--active');
                activeElementFound = true;
              } else {
                link.parentElement.classList.remove('template-content__first-navigation-link--active');
              }
            }
          }
        });
      }
    }

    if (burger instanceof HTMLElement) {
      burger.addEventListener('click', function () {
        const currentExpanded = burger.getAttribute('aria-expanded');

        currentExpanded === 'true' ? body.classList.remove('fixed') : body.classList.add('fixed');

        const newExpanded = currentExpanded === 'true' ? 'false' : 'true';

        burger.setAttribute('aria-expanded', newExpanded);
      });
    }

    if (closeButton instanceof HTMLElement) {
      closeButton.addEventListener('click', function () {
        const checkboxToggle = document.getElementById(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    }

    navigationLinks.forEach(navigationLink => {
      navigationLink.addEventListener('click', function () {
        const checkboxToggle = document.getElementById(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    });

    document.querySelectorAll('a[href^="#"]:not(.js-wpml-ls-item-toggle)').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
          const offset = 240;
          const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offset;

          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      });
    });

    document.querySelector('.navigation__avoid__link').addEventListener('click', function (e) {
      const target = document.querySelector('#stage');
      if (target) {
        target.setAttribute('tabindex', '-1');
        target.focus();
      }
    });
  };

  window.addEventListener('load', init);
})();
