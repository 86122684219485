import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splideContainer: '.js-text-media-container',
    splideElement: '.js-text-media',
    splideArrowNext: '.js-arrow-slider-next-text-media',
    splideArrowPrevious: '.js-arrow-slider-previous-text-media'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splideContainer);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        console.log(splide); // Ceci me donne un élément HTML et j'aimerais bien récupérer son enfant qui à la class js-text-media

        const splideArrowNext = splide.querySelector(selectors.splideArrowNext);
        const splideElement = splide.querySelector(selectors.splideElement);
        const splideArrowPrevious = splide.querySelector(selectors.splideArrowPrevious);

        const currentSplide = new Splide(splideElement, {
          type: 'loop',
          width: '100%',
          speed: 1000,
          perPage: splide.classList.contains('text-media--background-right') || splide.classList.contains('text-media--background-left') ? 1 : 1.5,
          direction: splide.classList.contains('text-media--right') ? 'ltr' : 'rtl',
          perMove: 1,
          breakpoints: {
            640: {
              perPage: 1,
              drag: true
            }
          },
          gap: 24,
          arrows: false,
          pagination: false
        }).mount();

        if (splideArrowNext instanceof HTMLElement && splideArrowPrevious instanceof HTMLElement) {
          splideArrowNext.addEventListener('click', e => {
            currentSplide.go('+1');
            console.log('next');
          });

          splideArrowPrevious.addEventListener('click', e => {
            currentSplide.go('-1');
            console.log('previous');
          });
        }
      }
    });
  };

  window.addEventListener('load', init);
})();
