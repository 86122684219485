(function () {
  'use strict';

  const classes = {
    active: 'card-news__article--active',
    paused: 'card-news__article--paused'
  };

  const selectors = {
    cards: '.parent__content-card-single'
  };

  const init = () => {
    const cards = document.querySelectorAll(selectors.cards);

    if (window.innerWidth > 1024) {
      for (const cardNew of cards) {
        cardNew.addEventListener('mouseenter', (e) => {
          addOpacityClassToCardNews();
          e.currentTarget.classList.add(classes.active);
        });

        cardNew.addEventListener('mouseleave', (e) => {
          removeOpacityClassToCardNews();
        });
      }
    }

    function addOpacityClassToCardNews () {
      for (const cardNew of cards) {
        cardNew.classList.add(classes.paused);
      }
    }

    function removeOpacityClassToCardNews () {
      for (const cardNew of cards) {
        cardNew.classList.remove(classes.paused);
        cardNew.classList.remove(classes.active);
      }
    }
  };

  window.addEventListener('load', init);
})();
